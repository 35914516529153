<template>
  <div>
    <Navbar page="Cadastros" video="https://www.youtube.com/watch?v=4WjCw-gZcHU"/>

    <div class="mx-4 md:mx-6 my-4">
      <div class="grid grid-cols-12 md:gap-x-9">
        <div class="col-span-12 2xl:col-span-12">
            <h5 @click="exclusivos = !exclusivos" class="text-base md:text-xl font-semibold text-gray-800 mb-3 mt-3 bg-yellow-300 rounded py-2 px-2 relative cursor-pointer" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                Itens Exclusivos
                <svg v-if="!exclusivos" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 absolute right-4 top-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                </svg>
                <svg v-if="exclusivos" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 absolute right-4 top-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                </svg>
            </h5>
            <div v-if="exclusivos" class="grid grid-cols-12 gap-2">
                <div class="col-span-6 md:col-span-3 2xl:col-span-2 mb-1 mt-1">
                  <router-link to="/cadastros/setores" class="flex flex-col items-center justify-center text-lg hover:bg-gray-100 py-2 rounded">
                    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="#000000" viewBox="0 0 256 256"><path d="M232,220H204V40a12,12,0,0,0-12-12H64A12,12,0,0,0,52,40V220H24a4,4,0,0,0,0,8H232a4,4,0,0,0,0-8ZM60,40a4,4,0,0,1,4-4H192a4,4,0,0,1,4,4V220H60Zm104,92a8,8,0,1,1-8-8A8,8,0,0,1,164,132Z"></path></svg>
                    <span class="text-sm_2 2xl:text-sm">Setores </span>
                  </router-link>
                </div>
                <div class="col-span-6 md:col-span-3 2xl:col-span-2 mb-1 mt-1">
                  <router-link to="/cadastros/funcoes" class="flex flex-col items-center justify-center text-lg hover:bg-gray-100 py-2 rounded">
                    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="#000000" viewBox="0 0 256 256"><path d="M196,112a4,4,0,0,1-4,4H152a4,4,0,0,1,0-8h40A4,4,0,0,1,196,112Zm-4,28H152a4,4,0,0,0,0,8h40a4,4,0,0,0,0-8Zm36-84V200a12,12,0,0,1-12,12H40a12,12,0,0,1-12-12V56A12,12,0,0,1,40,44H216A12,12,0,0,1,228,56Zm-8,0a4,4,0,0,0-4-4H40a4,4,0,0,0-4,4V200a4,4,0,0,0,4,4H216a4,4,0,0,0,4-4ZM131.87,167a4,4,0,1,1-7.74,2C121.06,157,109,148,96,148s-25,9-28.13,21A4,4,0,0,1,64,172a3.87,3.87,0,0,1-1-.13A4,4,0,0,1,60.13,167a36.28,36.28,0,0,1,20.43-23.66,28,28,0,1,1,30.88,0A36.2,36.2,0,0,1,131.87,167ZM96,140a20,20,0,1,0-20-20A20,20,0,0,0,96,140Z"></path></svg>
                    <span class="text-sm_2 2xl:text-sm">Funções </span>
                  </router-link>
                </div>
                <div class="col-span-6 md:col-span-3 2xl:col-span-2 mb-1 mt-1">
                  <router-link to="/cadastros/pessoas" class="flex flex-col items-center justify-center text-lg hover:bg-gray-100 py-2 rounded">
                    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="#000000" viewBox="0 0 256 256"><path d="M107.19,159a56,56,0,1,0-46.38,0A91.83,91.83,0,0,0,6.88,197.81a4,4,0,1,0,6.7,4.37,84,84,0,0,1,140.84,0,4,4,0,1,0,6.7-4.37A91.83,91.83,0,0,0,107.19,159ZM36,108a48,48,0,1,1,48,48A48.05,48.05,0,0,1,36,108Zm212,95.35a4,4,0,0,1-5.53-1.17A83.81,83.81,0,0,0,172,164a4,4,0,0,1,0-8,48,48,0,1,0-17.82-92.58,4,4,0,1,1-3-7.43,56,56,0,0,1,44,103,91.83,91.83,0,0,1,53.93,38.86A4,4,0,0,1,248,203.35Z"></path></svg>
                    <span class="text-sm_2 2xl:text-sm">Pessoas </span>
                  </router-link>
                </div>
                <div class="col-span-6 md:col-span-3 2xl:col-span-2 mb-1 mt-1">
                  <router-link to="/cadastros/agentestratamento" class="flex flex-col items-center justify-center text-lg hover:bg-gray-100 py-2 rounded">
                    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="#000000" viewBox="0 0 256 256"><path d="M240,212H220V96a12,12,0,0,0-12-12H140V32a12,12,0,0,0-18.66-10l-80,53.33a12,12,0,0,0-5.34,10V212H16a4,4,0,0,0,0,8H240a4,4,0,0,0,0-8ZM208,92a4,4,0,0,1,4,4V212H140V92ZM44,85.34A4,4,0,0,1,45.78,82l80-53.33A4,4,0,0,1,132,32V212H44ZM108,112v16a4,4,0,0,1-8,0V112a4,4,0,0,1,8,0Zm-32,0v16a4,4,0,0,1-8,0V112a4,4,0,0,1,8,0Zm0,56v16a4,4,0,0,1-8,0V168a4,4,0,0,1,8,0Zm32,0v16a4,4,0,0,1-8,0V168a4,4,0,0,1,8,0Z"></path></svg>
                    <span class="text-sm_2 2xl:text-sm">Agentes de Tratamento </span>
                  </router-link>
                </div>
                <div class="col-span-6 md:col-span-3 2xl:col-span-2 mb-1 mt-1">
                  <router-link to="/cadastros/titulares" class="flex flex-col items-center justify-center text-lg hover:bg-gray-100 py-2 rounded">
                    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="#000000" viewBox="0 0 256 256"><path d="M237,147.44a4,4,0,0,1-5.48-1.4c-8.33-14-20.93-22-34.56-22a4,4,0,0,1-1.2-.2,36.76,36.76,0,0,1-3.8.2,4,4,0,0,1,0-8,28,28,0,1,0-27.12-35,4,4,0,0,1-7.75-2,36,36,0,1,1,54,39.48c10.81,3.85,20.51,12,27.31,23.48A4,4,0,0,1,237,147.44ZM187.46,214a4,4,0,0,1-1.46,5.46,3.93,3.93,0,0,1-2,.54,4,4,0,0,1-3.46-2,61,61,0,0,0-105.08,0,4,4,0,0,1-6.92-4,68.35,68.35,0,0,1,39.19-31,44,44,0,1,1,40.54,0A68.35,68.35,0,0,1,187.46,214ZM128,180a36,36,0,1,0-36-36A36,36,0,0,0,128,180ZM64,116A28,28,0,1,1,91.12,81a4,4,0,0,0,7.75-2A36,36,0,1,0,45.3,118.75,63.55,63.55,0,0,0,12.8,141.6a4,4,0,0,0,6.4,4.8A55.55,55.55,0,0,1,64,124a4,4,0,0,0,0-8Z"></path></svg>
                    <span class="text-sm_2 2xl:text-sm">Titulares </span>
                  </router-link>
                </div>
                <div v-if="!$store.state.user.profissional" class="col-span-6 md:col-span-3 2xl:col-span-2 mb-1 mt-1">
                  <router-link to="/cadastros/profissionais" class="flex flex-col items-center justify-center text-lg hover:bg-gray-100 py-2 rounded">
                    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="#000000" viewBox="0 0 256 256"><path d="M115.88,217a4,4,0,0,1-3.88,3,4.09,4.09,0,0,1-1-.12l-32-8a4,4,0,0,1-1.25-.55l-24-16a4,4,0,0,1,4.44-6.66l23.42,15.62L113,212.12A4,4,0,0,1,115.88,217Zm132.77-96.75a11.88,11.88,0,0,1-6,6.93l-24.26,12.14-55.54,55.54a4,4,0,0,1-3.8,1l-64-16a3.84,3.84,0,0,1-1.36-.63L37.93,139.44,13.37,127.15A12,12,0,0,1,8,111.05L32.84,61.37h0A12,12,0,0,1,48.94,56L72.4,67.73l54.5-15.58a4.11,4.11,0,0,1,2.2,0l54.5,15.58L207.06,56a12,12,0,0,1,16.1,5.37L248,111.06A11.9,11.9,0,0,1,248.65,120.22ZM16.94,120l21.27,10.63L66.63,73.79,45.37,63.16A4,4,0,0,0,40,64.94L15.16,114.63A4,4,0,0,0,16.94,120ZM194,152.33l-33.78-27.06c-18.53,16.76-42.78,19.51-62.66,6.81a12,12,0,0,1-2.06-18.62l45.7-44.33A4,4,0,0,1,144,68h11.44L128,60.16,74.79,75.36,45.11,134.73l52.6,37.57,61.06,15.27Zm17.12-17.12L181.53,76H145.62l-44.49,43.16a3.89,3.89,0,0,0-1.11,3.2,4.07,4.07,0,0,0,1.85,3c13.95,8.91,35.8,11.44,55.29-8.2a4,4,0,0,1,5.34-.3l37.21,29.8Zm29.71-20.58L216,64.94a4,4,0,0,0-5.37-1.78L189.37,73.79l28.42,56.84L239.06,120a4,4,0,0,0,1.78-5.37Z"></path></svg>
                    <span class="text-sm_2 2xl:text-sm">Profissionais no projeto</span>
                  </router-link>
                </div>
                <div class="col-span-6 md:col-span-3 2xl:col-span-2 mb-1 mt-1">
                  <router-link to="/cadastros/organizacoes" class="flex flex-col items-center justify-center text-lg hover:bg-gray-100 py-2 rounded">
                    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="#000000" viewBox="0 0 256 256"><path d="M232,228H204V28h12a4,4,0,0,0,0-8H40a4,4,0,0,0,0,8H52V228H24a4,4,0,0,0,0,8H232a4,4,0,0,0,0-8ZM60,28H196V228H156V184a4,4,0,0,0-4-4H104a4,4,0,0,0-4,4v44H60Zm88,200H108V188h40ZM92,64a4,4,0,0,1,4-4h16a4,4,0,0,1,0,8H96A4,4,0,0,1,92,64Zm48,0a4,4,0,0,1,4-4h16a4,4,0,0,1,0,8H144A4,4,0,0,1,140,64ZM92,104a4,4,0,0,1,4-4h16a4,4,0,0,1,0,8H96A4,4,0,0,1,92,104Zm48,0a4,4,0,0,1,4-4h16a4,4,0,0,1,0,8H144A4,4,0,0,1,140,104ZM96,148a4,4,0,0,1,0-8h16a4,4,0,0,1,0,8Zm44-4a4,4,0,0,1,4-4h16a4,4,0,0,1,0,8H144A4,4,0,0,1,140,144Z"></path></svg>
                    <span class="text-sm_2 2xl:text-sm">Organizações </span>
                  </router-link>
                </div>
            </div>
        </div>
        <div class="col-span-12 2xl:col-span-12">
            <h5 @click="personalizaveis = !personalizaveis" class="text-base md:text-xl font-semibold text-gray-800 mb-3 mt-3 bg-yellow-300 rounded py-2 px-2 relative cursor-pointer" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                Itens Personalizáveis
                <svg v-if="!personalizaveis" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 absolute right-4 top-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                </svg>
                <svg v-if="personalizaveis" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 absolute right-4 top-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                </svg>
            </h5>
            <div v-if="personalizaveis" class="grid grid-cols-12 gap-2">
              <div class="col-span-6 md:col-span-3 2xl:col-span-2 mb-1 mt-1">
                <router-link to="/cadastros/categoriadados" class="flex flex-col items-center justify-center text-lg hover:bg-gray-100 py-2 rounded">
                  <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="#000000" viewBox="0 0 256 256"><path d="M210.83,85.17l-56-56A4,4,0,0,0,152,28H56A12,12,0,0,0,44,40V216a12,12,0,0,0,12,12H200a12,12,0,0,0,12-12V88A4,4,0,0,0,210.83,85.17ZM156,41.65,198.34,84H156ZM200,220H56a4,4,0,0,1-4-4V40a4,4,0,0,1,4-4h92V88a4,4,0,0,0,4,4h52V216A4,4,0,0,1,200,220Zm-50.74-52.39a32.05,32.05,0,1,0-5.65,5.65l13.56,13.57a4,4,0,0,0,5.66-5.66ZM100,148a24,24,0,1,1,24,24A24,24,0,0,1,100,148Z"></path></svg>
                  <span class="text-sm_2 2xl:text-sm">Categoria de dados pessoais</span>
                </router-link>
              </div>
              <div class="col-span-6 md:col-span-3 2xl:col-span-2 mb-1 mt-1">
                <router-link to="/cadastros/dados" class="flex flex-col items-center justify-center text-lg hover:bg-gray-100 py-2 rounded">
                  <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="#000000" viewBox="0 0 256 256"><path d="M192.14,42.55C174.94,33.17,152.16,28,128,28S81.06,33.17,63.86,42.55C45.89,52.35,36,65.65,36,80v96c0,14.35,9.89,27.65,27.86,37.45,17.2,9.38,40,14.55,64.14,14.55s46.94-5.17,64.14-14.55c18-9.8,27.86-23.1,27.86-37.45V80C220,65.65,210.11,52.35,192.14,42.55ZM212,176c0,11.29-8.41,22.1-23.69,30.43C172.27,215.18,150.85,220,128,220s-44.27-4.82-60.31-13.57C52.41,198.1,44,187.29,44,176V149.48c4.69,5.93,11.37,11.34,19.86,16,17.2,9.38,40,14.55,64.14,14.55s46.94-5.17,64.14-14.55c8.49-4.63,15.17-10,19.86-16Zm0-48c0,11.29-8.41,22.1-23.69,30.43C172.27,167.18,150.85,172,128,172s-44.27-4.82-60.31-13.57C52.41,150.1,44,139.29,44,128V101.48c4.69,5.93,11.37,11.34,19.86,16,17.2,9.38,40,14.55,64.14,14.55s46.94-5.17,64.14-14.55c8.49-4.63,15.17-10,19.86-16Zm-23.69-17.57C172.27,119.18,150.85,124,128,124s-44.27-4.82-60.31-13.57C52.41,102.1,44,91.29,44,80s8.41-22.1,23.69-30.43C83.73,40.82,105.15,36,128,36s44.27,4.82,60.31,13.57C203.59,57.9,212,68.71,212,80S203.59,102.1,188.31,110.43Z"></path></svg>
                  <span class="text-sm_2 2xl:text-sm">Dados pessoais </span>
                </router-link>
              </div>
              <div class="col-span-6 md:col-span-3 2xl:col-span-2 mb-1 mt-1">
                <router-link to="/cadastros/legislacoes" class="flex flex-col items-center justify-center text-lg hover:bg-gray-100 py-2 rounded">
                  <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="#000000" viewBox="0 0 256 256"><path d="M208,28H72A28,28,0,0,0,44,56V224a4,4,0,0,0,4,4H192a4,4,0,0,0,0-8H52v-4a20,20,0,0,1,20-20H208a4,4,0,0,0,4-4V32A4,4,0,0,0,208,28Zm-4,160H72a27.94,27.94,0,0,0-20,8.42V56A20,20,0,0,1,72,36H204Z"></path></svg>
                  <span class="text-sm_2 2xl:text-sm">Legislações</span>
                </router-link>
              </div>

              <div class="col-span-6 md:col-span-3 2xl:col-span-2 mb-1 mt-1">
                <router-link to="/cadastros/canais" class="flex flex-col items-center justify-center text-lg hover:bg-gray-100 py-2 rounded">
                  <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path d="M9 17a2 2 0 11-4 0 2 2 0 014 0zM19 17a2 2 0 11-4 0 2 2 0 014 0z" />
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0" />
                  </svg>
                  <span class="text-sm_2 2xl:text-sm">Canal de tratamento de dados </span>
                </router-link>
              </div>

              <div class="col-span-6 md:col-span-3 2xl:col-span-2 mb-1 mt-1">
                <router-link to="/cadastros/tipotitulares" class="flex flex-col items-center justify-center text-lg hover:bg-gray-100 py-2 rounded">
                  <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="#000000" viewBox="0 0 256 256"><path d="M148,80a4,4,0,0,1,4-4h96a4,4,0,0,1,0,8H152A4,4,0,0,1,148,80Zm100,44H152a4,4,0,0,0,0,8h96a4,4,0,0,0,0-8Zm0,48H176a4,4,0,0,0,0,8h72a4,4,0,0,0,0-8ZM147.87,191a4,4,0,0,1-2.87,4.87,3.87,3.87,0,0,1-1,.13,4,4,0,0,1-3.87-3c-6.71-26.08-32-45-60.13-45s-53.41,18.92-60.13,45a4,4,0,1,1-7.74-2c5.92-23,24.57-41.14,47.52-48a44,44,0,1,1,40.7,0C123.3,149.86,142,168,147.87,191ZM80,140a36,36,0,1,0-36-36A36,36,0,0,0,80,140Z"></path></svg>
                  <span class="text-sm_2 2xl:text-sm">Tipos de titulares de dados </span>
                </router-link>
              </div>

              <div class="col-span-6 md:col-span-3 2xl:col-span-2 mb-1 mt-1">
                <router-link to="/cadastros/finalidades" class="flex flex-col items-center justify-center text-lg hover:bg-gray-100 py-2 rounded">
                  <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M8 13v-1m4 1v-3m4 3V8M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z" />
                  </svg>
                  <span class="text-sm_2 2xl:text-sm">Finalidades </span>
                </router-link>
              </div>

              <div class="col-span-6 md:col-span-3 2xl:col-span-2 mb-1 mt-1">
                <router-link to="/cadastros/armazenamento" class="flex flex-col items-center justify-center text-lg hover:bg-gray-100 py-2 rounded">
                  <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="#000000" viewBox="0 0 256 256"><path d="M224,52H32A12,12,0,0,0,20,64V88a12,12,0,0,0,12,12h4v92a12,12,0,0,0,12,12H208a12,12,0,0,0,12-12V100h4a12,12,0,0,0,12-12V64A12,12,0,0,0,224,52ZM212,192a4,4,0,0,1-4,4H48a4,4,0,0,1-4-4V100H212ZM228,88a4,4,0,0,1-4,4H32a4,4,0,0,1-4-4V64a4,4,0,0,1,4-4H224a4,4,0,0,1,4,4ZM100,136a4,4,0,0,1,4-4h48a4,4,0,0,1,0,8H104A4,4,0,0,1,100,136Z"></path></svg>
                  <span class="text-sm_2 2xl:text-sm">Locais de armazenamento </span>
                </router-link>
              </div>

              <div class="col-span-6 md:col-span-3 2xl:col-span-2 mb-1 mt-1">
                <router-link to="/cadastros/medidasSeguranca" class="flex flex-col items-center justify-center text-lg hover:bg-gray-100 py-2 rounded">
                  <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="#000000" viewBox="0 0 256 256"><path d="M208,84H172V56a44,44,0,0,0-88,0V84H48A12,12,0,0,0,36,96V208a12,12,0,0,0,12,12H208a12,12,0,0,0,12-12V96A12,12,0,0,0,208,84ZM92,56a36,36,0,0,1,72,0V84H92ZM212,208a4,4,0,0,1-4,4H48a4,4,0,0,1-4-4V96a4,4,0,0,1,4-4H208a4,4,0,0,1,4,4Zm-84-92a24,24,0,0,0-4,47.66V184a4,4,0,0,0,8,0V163.66A24,24,0,0,0,128,116Zm0,40a16,16,0,1,1,16-16A16,16,0,0,1,128,156Z"></path></svg>
                  <span class="text-sm_2 2xl:text-sm">Medidas de segurança</span>
                </router-link>
              </div>

              <div class="col-span-6 md:col-span-3 2xl:col-span-2 mb-1 mt-1">
                <router-link to="/cadastros/riscos" class="flex flex-col items-center justify-center text-lg hover:bg-gray-100 py-2 rounded">
                  <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="#000000" viewBox="0 0 256 256"><path d="M208,44H48A12,12,0,0,0,36,56v58.77c0,86.88,73.54,115.7,88.28,120.59a11.47,11.47,0,0,0,7.44,0C146.46,230.49,220,201.67,220,114.79V56A12,12,0,0,0,208,44Zm4,70.79c0,81.38-69,108.41-82.81,113a3.51,3.51,0,0,1-2.39,0C113,223.2,44,196.17,44,114.79V56a4,4,0,0,1,4-4H208a4,4,0,0,1,4,4ZM124,136V96a4,4,0,0,1,8,0v40a4,4,0,0,1-8,0Zm12,36a8,8,0,1,1-8-8A8,8,0,0,1,136,172Z"></path></svg>
                  <span class="text-sm_2 2xl:text-sm">Gestão de riscos</span>
                </router-link>
              </div>

              <div class="col-span-6 md:col-span-3 2xl:col-span-2 mb-1 mt-1">
                <router-link to="/cadastros/baselegais" class="flex flex-col items-center justify-center text-lg hover:bg-gray-100 py-2 rounded">
                  <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z" />
                  </svg>
                  <span class="text-sm_2 2xl:text-sm">Bases legais </span>
                </router-link>
              </div>

              <div class="col-span-6 md:col-span-3 2xl:col-span-2 mb-1 mt-1">
                  <router-link to="/cadastros/whitelabel" class="flex flex-col items-center justify-center text-lg hover:bg-gray-100 py-2 rounded">
                    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="#000000" viewBox="0 0 256 256"><path d="M232,172H220V72a20,20,0,0,0-20-20H56A20,20,0,0,0,36,72V172H24a4,4,0,0,0-4,4v16a20,20,0,0,0,20,20H216a20,20,0,0,0,20-20V176A4,4,0,0,0,232,172ZM44,72A12,12,0,0,1,56,60H200a12,12,0,0,1,12,12V172H44ZM228,192a12,12,0,0,1-12,12H40a12,12,0,0,1-12-12V180H228ZM148,88a4,4,0,0,1-4,4H112a4,4,0,0,1,0-8h32A4,4,0,0,1,148,88Z"></path></svg>
                    <span class="text-sm_2 2xl:text-sm">White Label </span>
                  </router-link>
                </div>
            </div>
        </div>
        <div v-if="$store.state.user.admin" class="col-span-12 2xl:col-span-12">
            <h5 @click="restritos = !restritos" class="text-base md:text-xl font-semibold text-gray-800 mb-3 mt-3 bg-yellow-300 rounded py-2 px-2 relative cursor-pointer" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                Itens Restritos
                <svg v-if="!restritos" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 absolute right-4 top-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                </svg>
                <svg v-if="restritos" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 absolute right-4 top-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                </svg>
            </h5>
            <div v-if="restritos" class="grid grid-cols-12 gap-2">
              

              <div class="col-span-6 md:col-span-3 2xl:col-span-2 mb-1 mt-1">
                <router-link to="/cadastros/agentes" class="flex flex-col items-center justify-center text-lg hover:bg-gray-100 py-2 rounded">
                  <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                  </svg>
                  <span class="text-sm_2 2xl:text-sm">Agentes de tratamento </span>
                </router-link>
              </div>

              <div class="col-span-6 md:col-span-3 2xl:col-span-2 mb-1 mt-1">
                <router-link to="/cadastros/preatividade" class="flex flex-col items-center justify-center text-lg hover:bg-gray-100 py-2 rounded">
                  <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4" />
                  </svg>
                  <span class="text-xs md:text-sm_2">Banco de Atividades </span>
                </router-link>
              </div>

              <div class="col-span-6 md:col-span-3 2xl:col-span-2 mb-1 mt-1" v-if="$store.state.user.admin">
                <router-link to="/cadastros/direitos" class="flex flex-col items-center justify-center text-lg hover:bg-gray-100 py-2 rounded">
                  <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                  </svg>
                  <span class="text-xs md:text-sm_2">Direitos dos Titulares </span>
                </router-link>
              </div>

            </div>
        </div>
        
      </div>    
    </div>
  </div>
</template>

<script>
export default {
    name: 'Menu',
    data() {
        return {
            exclusivos: true,
            personalizaveis: true,
            restritos: true
        }
    },
}
</script>
